<template>
    <div class="sellCoin-wrap">
        <van-nav-bar
            left-arrow
            title="卖币"
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="sellCoin-main-wrap">
            <van-field center type="text" v-model="saleAmountValue" :formatter="formatter" autocomplete="off" :placeholder="`输入出售数量（${store.state.publicConfig.title}）`" @update:model-value="handleUpdateAmount">
                <template #left-icon>
                    <van-image :src="themeImgs.commonTransIcon" />
                </template>
                <template #label>
                    {{labelSaleAmount}}
                </template>
            </van-field>
            <p class="sale-amount">可售余额：{{availableBalance}} {{store.state.publicConfig.title}}</p>
            <p class="sale-amount" v-show="minSaleAmount">单次出售最小数量：{{minSaleAmount}}</p>
            <div class="payment-method-wrap">
                <span class="label">收款方式</span>
                <div class="payment-method-box">
                    <div class="method-item" :class="{'active':paymentIdArr.includes(type)}" v-for="type in paymentWayList" :key="type" @click="selectPaymentWay(type)">
                        <van-image :src="themeImgs[`commonPaymentIcon${type}`]" />
                    </div>
                </div>
            </div>
            <div class="sale-method-wrap" v-if="siteName !== 'qtpay'">
                <span class="label">出售方式</span>
                <div class="sale-method-box">
                  <div class="method-item" :class="{'active':sellWayId == 2}" @click="sellWayId=2">不拆分</div>
                  <div class="method-item" :class="{'active':sellWayId == 1}" @click="sellWayId=1">拆分</div>
                </div>
            </div>
            <div class="sale-btn-box">
                <van-button :disabled="availableBalance == '0.00' || btnClock" block @click="handleSale">出售</van-button>
            </div>
        </div>
        <!-- 支付密码弹出框 -->
        <van-dialog v-model:show="showPayPasswordDialog" title="支付密码" show-cancel-button :before-close="onBeforeClose">
            <van-field
                class="cancel-order-dialog-cell"
                v-model="pay_password"
                type="password"
                label="支付密码"
                placeholder="请输入支付密码"
                maxlength="6"
            />
        </van-dialog>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import { Toast,Dialog } from 'vant'
import {doSell,getPaymentList,getMemberAmount} from '@/api/api'

export default {
    setup () {
      const siteName = ref(siteChannel)
      const store = useStore()
        const loading = ref(false)
        const $router = useRouter()
        // 点击导航栏左侧返回键
        const onClickLeft = () => {
            history.back()
        }
        // 获取余额
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    availableBalance.value = res.data.info.currency_money
                }
            })
        }
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 1032) {
                    $router.push('/home')
                    return Toast.fail(res.msg)
                }
                if(res.result == 200000) {
                    if(JSON.stringify(res.data) == '{}') {
                        Dialog.confirm({
                            title: '提示',
                            message: '请先添加收付款方式'
                        })
                        .then(() => {
                            $router.push('/payment')
                        })
                        .catch(() => {
                            $router.push('/home')
                        });
                    } else {
                        for (let key in res.data) {
                            if(res.data[key].status == 1) {
                                paymentWayList.value.push(res.data[key].type)
                            }
                        }
                    }
                }
            })
        }
        onMounted(() => {
            handleGetMemberAmount()
            handleGetPaymentList()
        })
        // 定义出售的最少数量
        const minSaleAmount = ref(Number(store.state.sysConfig.min_amount))
        // 可售余额
        const availableBalance = ref(0)
        // 只能输入整数跟2位小数
        const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
        // 出售数量
        const saleAmountValue = ref('')
        // 展示出售数量
        const labelSaleAmount = ref(0)
        // 输入框输入值
        const handleUpdateAmount = (value) => {
            if (!value) {
                labelSaleAmount.value = 0
            } else {
                labelSaleAmount.value = value
            }
        }
        // 定义收款方式列表
        const paymentWayList = ref([])
        // 存储多选的收款方式id值
        const paymentIdArr = ref([])
        // 选择收款方式
        const selectPaymentWay = (i) => {
            if(paymentIdArr.value.includes(i)){
                //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
                //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
                paymentIdArr.value = paymentIdArr.value.filter(function (ele){return ele != i;});
            }else{
                paymentIdArr.value.push(i);
            }
        }
        // 出售方式
        const sellWayId = ref(2)
        // 是否显示支付密码弹框
        const showPayPasswordDialog = ref(false)
        // 支付密码变量
        const pay_password = ref('')
        // 取消默认关闭弹框事件
        const onBeforeClose = (action) => {
            // 点击了确定按钮
            if (action === "confirm") {
                if (!pay_password.value) {
                    Toast.fail('请输入支付密码')
                    return false
                } else {
                    handleSDosale()
                }
            }
            // 点击了取消按钮
            else {
                showPayPasswordDialog.value = false
                pay_password.value = ''
                btnClock.value = false
            }
        }
        // 点击出售按钮
        const handleSale = () => {
            if (btnClock.value) return
            if(!saleAmountValue.value) {
                return Toast.fail('请输入出售数量')
            } else if(saleAmountValue.value < minSaleAmount.value) {
                return Toast.fail('出售数量不能小于单次最小数量')
            } else if(!paymentIdArr.value.length) {
                return Toast.fail('请选择收款方式')
            } else {
                showPayPasswordDialog.value = true
            }
            btnClock.value = true
        }
        // 确定出售
        const handleSDosale = () => {
            if (loading.value) {
                return
            }

            loading.value = true
            let params = {
                amount: saleAmountValue.value,
                pay_type: paymentIdArr.value.sort((a,b)=>{return a-b}).toString(),
                type: sellWayId.value,
                pay_password: pay_password.value
            }
            doSell(params).then(res => {
              loading.value = false
              btnClock.value = false
              if(res.result == 200000) {
                    showPayPasswordDialog.value = false
                    pay_password.value = ''
                    Toast.success('上架成功')
                    saleAmountValue.value = ''
                    paymentIdArr.value = []
                    labelSaleAmount.value = 0
                    sellWayId.value = 2
                    handleGetMemberAmount()
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
              loading.value = false
              btnClock.value = false
              Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            onClickLeft,
            minSaleAmount,
            availableBalance,
            saleAmountValue,
            labelSaleAmount,
            handleUpdateAmount,
            paymentWayList,
            selectPaymentWay,
            paymentIdArr,
            sellWayId,
            handleSale,
            formatter,
            btnClock,
            themeImgs,
            showPayPasswordDialog,
            pay_password,
            onBeforeClose,
            store,
            siteName
        }
    }
}
</script>

<style lang="less" scoped>
.sellCoin-wrap {
    .sellCoin-main-wrap {
        min-height: calc(100vh - 46px);
        padding: 24px 16px 16px;
        background: var(--mainBgColor);
        :deep(.van-field) {
            height: 50px;
            border-radius: 8px;
            &::after {
                border: none;
            }
            .van-field__left-icon {
                display: flex;
                align-items: center;
                .van-image {
                    width: 32px;
                    height: 32px;
                }
            }
            .van-field__label {
                width: 36%;
                font-weight: 500;
                font-size: 26px;
                color: var(--textColor);
            }
        }
        .sale-amount {
            margin-top: 10px;
            font-weight: 500;
            color: var(--textColor);
        }
        .payment-method-wrap {
            display: flex;
            align-items: center;
            margin-top: 14px;
            .label {
                margin-right: 24px;
                font-weight: 500;
                font-size: 16px;
                color: var(--textCommonColor);
            }
            .payment-method-box {
                display: flex;
                align-items: center;
                .method-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 30px;
                    margin-right: 20px;
                    &.active {
                        border: 1px solid var(--textColor);
                        border-radius: 8px;
                    }
                    .van-image {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .sale-method-wrap {
            display: flex;
            align-items: center;
            margin-top: 14px;
            .label {
                margin-right: 24px;
                font-weight: 500;
                font-size: 16px;
                color: var(--textCommonColor);
            }
            .sale-method-box {
                display: flex;
                align-items: center;
                .method-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 70px;
                    height: 30px;
                    margin-right: 20px;
                    background: var(--notSplitBgColor);
                    border-radius: 8px;
                    color: var(--textColor);
                    &.active {
                        background: var(--btnBgColor);
                        color: #FFFFFF;
                    }
                }
            }
        }
        .sale-btn-box {
            margin-top: 48px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
    :deep(.van-dialog) {
        &.code-dialog {
            .van-dialog__header {
                background: var(--orderDialogHeaderBgColor);
            }
            .van-dialog__confirm {
                background: var(--orderDialogHeaderBgColor);
                color: var(--orderDialogTextColor);
            }
        }
        .van-dialog__header {
            padding: 21px 0;
            font-weight: 500;
            font-size: 18px;
            color: var(--orderDialogTextColor);
        }
        .code-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 275px;
            background-size: 100% 100% !important;
            .van-image {
                width: 158px;
                height: 158px;
                border-radius: 8px;
            }
            .name {
                margin-top: 20px;
                font-size: 16px;
                color: rgba(83, 92, 102, 0.7);
            }
        }
        .cancel-order-dialog-cell {
            background: none;
            input::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            input:-ms-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            input:-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            input::-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            textarea::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            textarea:-ms-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            textarea:-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            textarea::-moz-placeholder {
                color: rgba(255, 255, 255, 0.6);
                opacity: 1;
            }
            .van-field__label {
                color: #fff;
            }
            .van-field__control {
                color: #fff;
            }
        }
    }
}
</style>
